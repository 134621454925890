import React, { useState, useEffect } from 'react';
import HomeMobileIcon from '../../assets/images/mocana/mocana-mobile-logo.svg';
import Modal from '../Modal';
import Socials from './HeaderSocials';
import ConnectWallet from './ConnectWallet';
import NavLinks from './NavLinks';

const MobileNavWrapper = ({
  links,
  className,
  address,
  handleGetUpdate,
  handleDisconnect,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (address) {
      setShowModal(false);
    }
  }, [address]);

  return (
    <>
      {showModal && <Modal toggleModal={() => setShowModal(false)} />}
      <nav>
        <div className={!isActive ? 'mobile close' : ' mobile open'}>
          <div
            className={!isActive ? 'mobile-btn close' : ' mobile-btn open'}
            onClick={handleClick}
          >
            <img src={HomeMobileIcon} className="mobile-moca-icon" alt="" />
          </div>
          <ConnectWallet
            address={address}
            handleGetUpdate={handleGetUpdate}
            handleDisconnect={handleDisconnect}
            handleClick={() => setShowModal(true)}
          />
          <div className={`mobile-nav ${className}`}>
            <ul>
              <NavLinks
                type="mobile"
                links={links}
                handleMobileItemClick={handleClick}
              />
            </ul>
            <Socials />
          </div>
          <div className="navBg" />
          <div className="navoverlay" onClick={handleClick} />
        </div>
      </nav>
    </>
  );
};

export default MobileNavWrapper;
