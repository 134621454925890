import React, { useEffect } from 'react';
import Wrapper from '../components/mocana/Wrapper';
import MocaXp from '../components/mocana/MocaXp';
import MocanaHeader from '../components/mocana/MocanaHeader';
import { Socials } from '../components';
import MocanaContentHeader from '../components/mocana/MocanaContentHeader';
import MocanaEvent from '../components/event/MocanaEvent';
import EventHeader from '../components/event/EventHeader';

const Mocana = () => {
  useEffect(() => {
    document.body.classList = 'mocana-page';
  });
  return (
    <Wrapper>
      <EventHeader />

      <MocanaHeader />
      <Socials />
      <MocanaEvent />
      <MocanaContentHeader />
      <MocaXp />
    </Wrapper>
  );
};
export default Mocana;
