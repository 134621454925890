import { env } from '../utils/envHelpers';

const callAPI = async (method, endpoint, body = null) => {
  const options = {
    method,
    headers: { 'Content-type': 'application/json' },
    credentials: 'include',
    ...(method === 'POST'
      ? {
          body: JSON.stringify(body),
        }
      : {}),
  };

  try {
    const res = await fetch(`${env.apiUrl}${endpoint}`, options);
    const el = await res.json();
    return el;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getEligibility = () => callAPI('GET', '/eligibility');

export const submitLicense = (sig) =>
  callAPI('POST', '/license', { signature: sig, project: 'mocaverse' });

export const postMint = (currency_address, quantity, signature) =>
  callAPI('POST', '/mint', {
    currency_address,
    quantity,
    signature,
  });

export const fetchSurveyItems = () => callAPI('GET', '/survey');

export const fetchLeaderBoard = (param) =>
  callAPI(
    'GET',
    `/leaderboard?search=${param.search}&page=${param.page}&offset=${param.offset}&tribe=${param.tribe}&ordering=${param.ordering}`
  );
// get moca
export const fetchMocaEvent = (param) =>
  callAPI(
    'GET',
    `/moca?event_id=${param.event_id}&wallet_address=${param.wallet_address}`
  );

export const fetchEventRegistration = (param) =>
  callAPI(
    'GET',
    `/event-registration?event_id=${param.event_id}&wallet=${param.wallet_address}`
  );
export const submitEventRegistration = async (data) =>
  callAPI('POST', '/event-registration', data);

export const fetchRealmBalance = (param) =>
  callAPI('GET', `/realm-balance?wallet_address=${param.wallet_address}`);

export const submitSurvey = (data) =>
  callAPI('POST', '/survey', { option_ids: data });

export const checkQuotas = () => callAPI('GET', '/quotas');

export const getSupplies = async () => callAPI('GET', '/supplies');

export const getRealms = async () => callAPI('GET', '/realms');

export const submitRealms = async (realm_id, token_id) =>
  callAPI('POST', '/realms', {
    realm_id,
    token_id,
  });

export const submitGettingUpdates = async (address, email) => {
  const data =
    address && email
      ? {
          address,
          email,
        }
      : {
          email,
        };
  return callAPI('POST', '/email', data);
};
