import React from 'react';
import soldoutLogo from '../../assets/images/sold-out-title.png';
import openSeaIcon from '../../assets/images/icon-opens.svg';

const IntroSoldout = () => (
  <>
    <img
      src={soldoutLogo}
      alt="All Mocas are minted!"
      className="sold-out-logo"
    />
    <div className="sold-out-info">
      <p>But you can always join the MocaFam on the next ship!</p>
      <p className="txt-md">
        <a
          href="https://opensea.io/collection/mocaverse"
          target="_blank"
          rel="noreferrer"
          title="opensea collection"
        >
          <img
            src={openSeaIcon}
            alt="icon-opensea"
            className="icon-opensea-btn"
          />
          OpenSea
        </a>
      </p>
    </div>
  </>
);

export default IntroSoldout;
