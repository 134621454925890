import React from 'react';
import { TimeRemindIcon } from '../common/Icons';

const MocanaEvent = () => (
  <div className="mocana-event-wrapper">
    <div className="mocana-event">
      <h2 className="mocana-event-title">Into The Mocaverse with Gamee</h2>
      <p className="mocana-event-title-date">
        <TimeRemindIcon className="mocana-event-title-icon" />
        20 Apr 13:00:00 - 27 Apr 13:00:00 (UTC)
      </p>
    </div>
    <a
      href="/event?type=partnership"
      className="btn btn-md-second btn-view-experience"
      title="View Experience"
    >
      View Experience
    </a>
  </div>
);

export default MocanaEvent;
