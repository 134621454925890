import React, { useEffect } from 'react';
import EventWrapperTest from '../components/event/EventWrapperTest';

const Event_Test = () => {
  useEffect(() => {
    document.body.classList = 'mocana-page event-page';
  });

  return <EventWrapperTest />;
};

export default Event_Test;
