export const EVENT_MESSAGE = {
  NOT_FOUND: 'Registered Moca not found. Not eligible for rewards',
  REGISTERED: 'You are registered in this event.',
  REGISTERED_WITH_REAML: 'You are registered in this event with',
};

export const EVENT_TIME_STATUS = {
  NOT_STARTED: 'not-started',
  LIVE: 'live',
  ENDED: 'ended',
};

export const EVENT_REGISTRATION_STATUS = {
  NOT_STARTED: 'not-started',
  IN_PROCESS: 'in-process',
  ENDED: 'ended',
};

export const EVENT_RESULT_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
};
