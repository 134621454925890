/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import HomeIcon from '../../assets/images/moca-home.png';
import Modal from '../Modal';
import ConnectWallet from './ConnectWallet';

const DesktopNav = ({
  showDesktopNav,
  address,
  handleGetUpdate,
  handleDisconnect,
}) => {
  const [showModal, setShowModal] = useState(false);

  const top = showDesktopNav.display ? 0 : -1000;

  const handleClick = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (address) {
      setShowModal(false);
    }
  }, [address]);

  return (
    <>
      {showModal && <Modal toggleModal={() => setShowModal(false)} />}
      <nav
        style={{
          top,
        }}
        className="nav-desktop"
      >
        <div className="desktop">
          <li>
            <a
              href="/#home"
              className="home"
              onClick={() => showDesktopNav({ display: true })}
            >
              <img src={HomeIcon} alt="Mocaverse" />
            </a>
          </li>
          <li>
            <a href="/#story-of-mocas">Story of The Mocas</a>
          </li>
          <li>
            <a href="/#realms">Realms</a>
          </li>
          <li>
            <a href="/#what-is-mocaverse">What is Mocaverse?</a>
          </li>
          <li>
            <a href="/#faq">FAQ</a>
          </li>
          <li>
            <div className="connect-wrapper">
              <div className="connect btn">
                <a href="/mocana">Visit Mocana</a>
              </div>
            </div>
          </li>
        </div>
      </nav>
    </>
  );
};

export default React.memo(DesktopNav);
