import React from 'react';
import './assets/scss/style.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThirdwebProvider } from '@thirdweb-dev/react';
import Home from './pages/Home';
import Error from './pages/404';
import { Event } from './pages/index';
import Event_Test from './pages/Event-Test';

import Mocana from './pages/Mocana';
// import Moca from './pages/Moca';

// import Packing from './pages/Packing';
// import Personality from './pages/Personality';
// import Completion from './pages/Completion';
// import Mint from './pages/Mint';

// import Utilities from './pages/Utilities';
import { env } from './utils/envHelpers';
import { MintingProgressProvider } from './context/stateManager';
import ScrollToTop from './components/common/ScrollToTop';

const MusicPlayer = React.lazy(() => import('./components/MusicPlayer'));

const App = () => (
  <ThirdwebProvider
    desiredChainId={env.desiredChainId}
    authConfig={{
      authUrl: `${env.authUrl}/auth`,
      domain: `${env.domain}`,
      loginRedirect: `${env.siteUrl}`,
    }}
    dAppMeta={{
      name: 'Mocaverse',
      description:
        'Mocaverse is the Animoca Brands membership NFT collection of 8,888 unique beings: The Mocas. Join the Mocas in the realms to Learn, Play, Do Good, and Build together as we champion our Web3 values!',
      url: 'https://www.mocaverse.xyz/',
    }}
  >
    <MintingProgressProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mocana" element={<Mocana />} />
          <Route path="/event" element={<Event />} />
          <Route path="/event-test" element={<Event_Test />} />

          {/* <Route path="/utilities" element={<Utilities />} /> */}
          <Route path="*" element={<Error />} />
          {/* <Route path="/moca" element={<Moca />} />
          <Route path="/packing" element={<Packing />} />
          <Route path="/personality" element={<Personality />} />
          <Route path="/completion" element={<Completion />} /> */}
          {/* <Route path="/mint" element={<Mint />} /> */}
        </Routes>
        <MusicPlayer />
      </BrowserRouter>
    </MintingProgressProvider>
  </ThirdwebProvider>
);

export default App;
