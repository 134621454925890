/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import HomeIcon from '../../assets/images/moca-home.png';
import Modal from '../Modal';
import Socials from './HeaderSocials';
import ConnectWallet from './ConnectWallet';

const MobileNav = ({ address, handleGetUpdate, handleDisconnect }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (address) {
      setShowModal(false);
    }
  }, [address]);

  return (
    <>
      {showModal && <Modal toggleModal={() => setShowModal(false)} />}
      <nav>
        <div className={!isActive ? 'mobile close' : ' mobile open'}>
          <div
            className={!isActive ? 'mobile-btn close' : ' mobile-btn open'}
            onClick={handleClick}
          >
            <img src={HomeIcon} className="mobile-moca-icon" alt="" />
            <span>Mocaverse</span>
          </div>
          <div className="connect-wrapper">
            <div className="connect btn">
              <a href="/mocana">Visit Mocana</a>
            </div>
          </div>
          <div className="mobile-nav">
            <ul>
              <li>
                <a href="/#home" onClick={handleClick}>
                  <span className="icon" /> Mocaverse
                </a>
              </li>
              <li>
                <a href="/#story-of-mocas" onClick={handleClick}>
                  Story of The Mocas
                </a>
              </li>
              <li>
                <a href="/#realms" onClick={handleClick}>
                  Realms
                </a>
              </li>
              <li>
                <a href="/#what-is-mocaverse" onClick={handleClick}>
                  What is Mocaverse?
                </a>
              </li>
              <li className="last">
                <a href="#faq" onClick={handleClick}>
                  FAQ
                </a>
              </li>
            </ul>
            <Socials />
          </div>
          <div className="navBg" />
          <div className="navoverlay" onClick={handleClick} />
        </div>
      </nav>
    </>
  );
};

export default React.memo(MobileNav);
