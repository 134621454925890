import React from 'react';
import Discord from '../assets/images/discord-black.png';
import Twitter from '../assets/images/twitter-black.png';
import OpenSea from '../assets/images/opensea-black.png';
import Etherscan from '../assets/images/etherscan-black.png';
import { env } from '../utils/envHelpers';

const Socials = (props) => (
  <div className="socials-links">
    <ul className={props?.bgColor}>
      <li>
        <a
          href="http://discord.gg/MocaverseNFT"
          target="_blank"
          title="Discord"
          rel="noreferrer"
          className="socials"
        >
          <img className="discord" src={Discord} alt="Discord" />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/MocaverseNFT"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Twitter} alt="Twitter" />
        </a>
      </li>
      <li>
        <a
          href="https://opensea.io/collection/mocaverse"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={OpenSea} alt="OpenSea" />
        </a>
      </li>
      <li>
        <a
          href={env.contractUrl}
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Etherscan} alt="Etherscan" />
        </a>
      </li>
    </ul>
  </div>
);

export default React.memo(Socials);
