import React from 'react';

const LeaderBoardTest = ({ data }) => {
  if (!data) {
    return <></>;
  }
  return (
    <div className="leader-board-wrapper test1">
      <div className="left">
        <p className="event-description">
          description: <b>{data.description}</b>
        </p>
        <p className="event-description-mobile">
          chain: <b>{data.chain}</b>
        </p>
        <p className="event-description-mobile">
          raffle type: <b>{data.raffle_type}</b>
        </p>
        <p className="event-description-mobile">
          requirements: <br />
          <br />
          {JSON.stringify(data.campaign_requirements)}
        </p>
      </div>

      <div className="right">
        <iframe
          id="iframe"
          src={`https://nft-mpc-test.lifo.ai/public/mocaverse-campaign-challenge/${data.id}`}
          frameBorder="0"
          style={{ width: 500, height: 700 }}
          title="Challenge"
        />
      </div>
    </div>
  );
};
export default LeaderBoardTest;
