import React from 'react';
import Discord from '../../assets/images/discord-yellow.png';
import Twitter from '../../assets/images/twitter-yellow.png';
import OpenSea from '../../assets/images/opensea-yellow.png';
import Etherscan from '../../assets/images/etherscan-yellow.png';
import { env } from '../../utils/envHelpers';

const IntroSocials = () => (
  <div className="socials-links-mobile">
    <ul>
      <li>
        <a
          href="http://discord.gg/MocaverseNFT"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Discord} alt="Discord" />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/MocaverseNFT"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Twitter} alt="Twitter" />
        </a>
      </li>
      <li>
        <a
          href="https://opensea.io/collection/mocaverse"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={OpenSea} alt="OpenSea" />
        </a>
      </li>
      <li>
        <a
          href={env.contractUrl}
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Etherscan} alt="Etherscan" />
        </a>
      </li>
    </ul>
  </div>
);

export default IntroSocials;
