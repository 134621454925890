import React from 'react';
import {
  LearnAnimation,
  InvestAnimation,
  PlayAnimation,
  DoGoodAnimation,
  LearnTextAnimation,
  InvestTextAnimation,
  PlayTextAnimation,
  DoGoodTextAnimation,
} from './realms/index';

const PlayerRealms = () => (
  <>
    <div className="learn-realm">
      <LearnTextAnimation />
      <LearnAnimation />
    </div>
    <div className="dogood-realm">
      <DoGoodTextAnimation />
      <DoGoodAnimation />
    </div>
    <div className="invest-realm">
      <InvestTextAnimation />
      <InvestAnimation />
    </div>
    <div className="play-realm">
      <PlayTextAnimation />
      <PlayAnimation />
    </div>
  </>
);

export default React.memo(PlayerRealms);
