import React from 'react';
import homeLogo from '../assets/images/mocaverse.svg';
import IntroSocials from './intro/IntroSocials';
import PlayerRealms from './PlayerRealms';
import IntroSoldout from './intro/IntroSoldout';

const Intro = () => (
  <section id="home" className="intro orbit-rings sold-out">
    <div className="intro__wrapper">
      <div className="home-logo-counter-block">
        <div className="home-wrapper">
          <img src={homeLogo} alt="OFF TO THE REALMS" className="home-logo" />
          <IntroSoldout />
        </div>
        <IntroSocials />
      </div>
      <PlayerRealms />
    </div>
  </section>
);

export default Intro;
