/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import {
  EVENT_REGISTRATION_STATUS,
  EVENT_RESULT_TYPE,
} from '../../utils/eventHelpers';
import TicketTinyImg from '../../assets/images/mocana/ticket-tiny.png';
import GetMocaImg from '../../assets/images/mocana/get-moca-icon.png';
import { MocaNotFoundIcon } from '../common/Icons';
import EventMsg from './EventMsg';

const EventNoMoca = ({ status, realms, handleRegister, error }) => {
  const actions = useMemo(() => {
    if (status !== EVENT_REGISTRATION_STATUS.IN_PROCESS) {
      return (
        <div className="event-sb-actions">
          <button className="btn btn-md-second btn-register-now" disabled>
            Register Now
          </button>
        </div>
      );
    }

    if (realms) {
      return (
        <>
          <button
            className="btn btn-md-second btn-register-with-realms"
            aria-label="Register with Realms"
            onClick={handleRegister}
          >
            Register with{' '}
            <img className="event-sb-or-img" src={TicketTinyImg} alt="Ticket" />
            x1
          </button>
          <div className="event-sb-or-note">
            <p>
              You have{' '}
              <img
                className="event-sb-or-note-img"
                src={TicketTinyImg}
                alt="Ticket"
              />{' '}
              x{realms}
            </p>
          </div>
        </>
      );
    }

    return (
      <>
        <button
          className="btn btn-md-second btn-register-with-realms"
          aria-label="Register with Realms"
          disabled
        >
          Register with{' '}
          <img className="event-sb-or-img" src={TicketTinyImg} alt="Ticket" />
          x1
        </button>
        <div className="event-sb-or-note no-ticket">
          <p className="event-sb-or-no-ticket">
            You have{' '}
            <img
              className="event-sb-or-note-img"
              src={TicketTinyImg}
              alt="Ticket"
            />{' '}
            x0
          </p>
          <a
            href="https://opensea.io/collection/mocaverse-realm-ticket"
            title="Get Realm Ticket"
            target="_blank"
            rel="noreferrer"
          >
            Get Realm Ticket
          </a>
        </div>
      </>
    );
  }, [status, realms, handleRegister]);

  return (
    <>
      {error && (
        <EventMsg
          type={EVENT_RESULT_TYPE.ERROR}
          message={error?.msg}
          // txnHash={error?.txnHash}
        />
      )}
      <div className="event-sb-header">
        <h2 className="event-sb-title">Select a Moca to be your Agent</h2>
      </div>
      <div className="event-sb-main event-no-moca">
        <MocaNotFoundIcon
          className="event-no-moca-icon"
          aria-label="Moca Not Found"
        />
        <h3>No Moca Found in Wallet</h3>
        <p>Get a Moca to register in upcoming experiences</p>
        <a
          href="https://opensea.io/collection/mocaverse"
          className="btn btn-md-second btn-get-moca"
          title="Get Realm Ticket"
          target="_blank"
          rel="noreferrer"
        >
          <img src={GetMocaImg} className="btn-get-moca-icon" alt="Get Moca" />
          Get Moca
        </a>
      </div>
      {status === EVENT_REGISTRATION_STATUS.IN_PROCESS && (
        <div className="event-sb-or-actions">
          <p className="event-sb-or-txt">
            <span>or</span>
          </p>
          {actions}
        </div>
      )}
    </>
  );
};

export default EventNoMoca;
