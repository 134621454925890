export const tribesFilter = [
  {
    id: 'angel',
    name: 'Angel',
  },
  {
    id: 'builder',
    name: 'Builder',
  },
  {
    id: 'connector',
    name: 'Connector',
  },
  {
    id: 'dreamer',
    name: 'Dreamer',
  },
  {
    id: 'neo-capitalist',
    name: 'Neo-Capitalist',
  },
];
export const leaderBoardSort = [
  {
    id: 'rank',
    name: 'RANK: High to Low',
  },
  {
    id: '-rank',
    name: 'RANK: Low to High',
  },
  {
    id: 'tribe',
    name: 'TRIBE: A to Z',
  },
  {
    id: '-tribe',
    name: 'TRIBE: Z to A',
  },
];
export const leaderBoardEventSort = [
  {
    id: '-rank_max_score',
    name: 'RANK: High to Low',
  },
  {
    id: 'rank_max_score',
    name: 'RANK: Low to High',
  },
  {
    id: 'tribe',
    name: 'TRIBE: A to Z',
  },
  {
    id: '-tribe',
    name: 'TRIBE: Z to A',
  },
  {
    id: 'nickname',
    name: 'Gamee ID: A to Z',
  },
  {
    id: '-nickname',
    name: 'Gamee ID: Z to A',
  },
];
export const leaderBoardRealmEventSort = [
  {
    id: '-rank_max_score',
    name: 'RANK: High to Low',
  },
  {
    id: 'rank_max_score',
    name: 'RANK: Low to High',
  },
  {
    id: 'nickname',
    name: 'Gamee ID: A to Z',
  },
  {
    id: '-nickname',
    name: 'Gamee ID: Z to A',
  },
];
export const SORT_OPTIONS = {
  RANK: 'rank_max_score',
  MOCA_NAME: 'moca_name',
  WALLET: 'wallet_address',
  NICKNAME: 'nickname',
  TRIBE: 'tribe',
  MAX_SCORE: 'max_score',
};
export const ITEMSPERPAGE = 15;
export const API_EVENT_JSON = {
  MOCA: 'https://mocaverse-resources.s3.us-west-2.amazonaws.com/gamee/gamee_leaderboard_mocas.json',
  REALM:
    'https://mocaverse-resources.s3.us-west-2.amazonaws.com/gamee/gamee_leaderboard_realm.json',
  TRIBE:
    'https://mocaverse-resources.s3.us-west-2.amazonaws.com/gamee/gamee_leaderboard_tribe.json',
};
