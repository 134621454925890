import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import EventHeader from './EventHeader';
import EventTitleTest from './EventTitleTest';

import LeaderBoardTest from './LeaderBoardTest';

const EventWrapperTest = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [data, setData] = useState();

  console.log(id, 77, data);
  useEffect(() => {
    fetch(`https://api-dev.w3w.ai/v1/brand/campaign/${id}/detail`, {
      headers: {
        'x-api-key': '63bee598-9c5f-4280-8a40-f58007239a18',
      },
    })
      .then((response) => response.json())
      .then((values) => {
        setData(values);
      })
      .catch((error) => {
        console.error('Error fetching leaderboard data:', error);
      });
  }, []);
  return (
    <div className="mocana-wrapper test">
      <EventHeader />
      <EventTitleTest data={data} />

      <LeaderBoardTest data={data} />
    </div>
  );
};

export default EventWrapperTest;
