import React, { useState, useEffect } from 'react';
import { formatDotDot } from '../../utils/textHelper';
// import DashboardLinks from '../dashboard/DashboardLinks';

const ConnectWallet = ({
  address,
  handleClick,
  handleGetUpdate,
  handleDisconnect,
}) => {
  const [isShow, setShow] = useState(false);
  const [copied, setCopied] = useState(false);

  const connectionName = window.localStorage.getItem('connectName') || '';

  const formatingAddress = address ? formatDotDot(address) : '';

  const clsOpen = isShow ? 'open' : '';

  const handleShow = () => {
    setShow(!isShow);
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(address);
    setCopied(true);
  };

  const handleUpdate = () => {
    setShow(false);
    handleGetUpdate();
  };

  useEffect(() => {
    if (!address) {
      setShow(false);
    }
  }, [address]);

  useEffect(() => {
    if (!isShow) {
      setCopied(false);
    }
  }, [isShow]);

  if (address) {
    return (
      <div className="connect-wrapper">
        <div className={`connect-select ${clsOpen}`}>
          <span className="connect-select-title" onClick={handleShow}>
            {formatingAddress}
          </span>
          <div className="connect-select-content">
            {connectionName && (
              <div className="connect-name">{connectionName}</div>
            )}
            <ul>
              <li>
                <button className="btn btn-update" onClick={handleUpdate}>
                  Get Updated!
                </button>
              </li>
              {/* <DashboardLinks /> */}
              <li>
                {!copied ? (
                  <button className="btn-icon btn-copy" onClick={handleCopy}>
                    Copy address
                  </button>
                ) : (
                  <span className="btn-icon wallet-copied">
                    Address copied!
                  </span>
                )}
              </li>
              {/* <li>
                <button
                  className="btn-icon btn-change-wallet"
                  onClick={handleClick}
                >
                  Change wallet
                </button>
              </li> */}
              <li>
                <button
                  className="btn-icon btn-disconnect"
                  onClick={handleDisconnect}
                >
                  Disconnect wallet
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="connect-wrapper">
      <div className="connect btn" onClick={handleClick}>
        <span>Connect</span>
      </div>
    </div>
  );
};

export default React.memo(ConnectWallet);
