import React, { useEffect, useState } from 'react';
import NoData from '../NoData';
import Sort from '../Sort';
import Table from './TableMoca';
import { API_EVENT_JSON } from '../../../utils/constants';
import iconSort from '../../../assets/images/mocana/icon-down.svg';
import iconSearch from '../../../assets/images/mocana/icon-search.svg';
import { useDebounce } from '../../../hooks/useDebounce';
import { sanitizeInput } from '../../../utils/mocanaHelper';

import mocaIcon from '../../../assets/images/moca-icon.svg';
import iconClose from '../../../assets/images/mocana/close.svg';
import iconRemoveS from '../../../assets/images/mocana/icon-remove-s.svg';

const MocaLeaderboard = () => {
  const [showSort, setShowSort] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTotalScore, setIsTotalScore] = useState(false);

  const [count, setCount] = useState(0);

  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchTerm = useDebounce(searchInput, 400);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [ordering, setOrdering] = useState('rank_max_score');
  const [data, setData] = useState();
  const [initData, setInitData] = useState();

  const handleChangeParam = () => {
    if (page !== 1 || offset !== 0) {
      setPage(1);
      setOffset(0);
    }
  };
  const handleInputChange = (event) => {
    handleChangeParam();
    setSearchInput(event.target.value);
  };

  const handleRemoveSearch = () => {
    setSearchInput('');
  };

  const handleShowSort = () => {
    setShowSort(!showSort);
  };

  useEffect(() => {
    if (showSort) {
      document.body.classList.add('active-sort');
    } else {
      document.body.classList.remove('active-sort');
    }
  }, [showSort]);

  // Sort filtered data based on ordering
  const handleSortedData = (filteredData) => {
    switch (ordering) {
      case 'rank_max_score':
        return isTotalScore
          ? filteredData.sort((a, b) => a.rank_total_score - b.rank_total_score)
          : filteredData.sort((a, b) => a.rank_max_score - b.rank_max_score);
      case '-rank_max_score':
        return isTotalScore
          ? filteredData.sort((a, b) => b.rank_total_score - a.rank_total_score)
          : filteredData.sort((a, b) => b.rank_max_score - a.rank_max_score);
      case 'moca_name':
        return filteredData.sort((a, b) =>
          a.moca_name.localeCompare(b.moca_name)
        );
      case '-moca_name':
        return filteredData.sort((a, b) =>
          b.moca_name.localeCompare(a.moca_name)
        );
      case 'tribe':
        return filteredData.sort((a, b) => a.tribe.localeCompare(b.tribe));
      case '-tribe':
        return filteredData.sort((a, b) => b.tribe.localeCompare(a.tribe));
      case 'nickname':
        return filteredData.sort((a, b) =>
          a.nickname.localeCompare(b.nickname)
        );
      case '-nickname':
        return filteredData.sort((a, b) =>
          b.nickname.localeCompare(a.nickname)
        );
      case 'max_score':
        return isTotalScore
          ? filteredData.sort((a, b) => a.total_score - b.total_score)
          : filteredData.sort((a, b) => a.max_score - b.max_score);
      case '-max_score':
        return isTotalScore
          ? filteredData.sort((a, b) => b.total_score - a.total_score)
          : filteredData.sort((a, b) => b.max_score - a.max_score);
      default:
        return filteredData;
    }
  };

  useEffect(() => {
    // Filter data based on search input
    if (initData) {
      const filteredData = initData.filter(
        (item) =>
          item.nickname
            .toLowerCase()
            .includes(sanitizeInput(debouncedSearchTerm).toLowerCase()) ||
          item.moca_name
            .toLowerCase()
            .includes(sanitizeInput(debouncedSearchTerm).toLowerCase())
      );

      const sortedData = handleSortedData(filteredData);
      // Calculate total count
      const totalCount = sortedData.length;
      // Set the data for the current page and offset
      const dataForPage = sortedData?.slice(offset, offset + 15);

      setCount(totalCount);
      setData(dataForPage);
    }
  }, [debouncedSearchTerm, page, offset, ordering, initData, isTotalScore]);

  useEffect(() => {
    setLoading(true);
    fetch(API_EVENT_JSON.MOCA)
      .then((response) => response.json())
      .then((values) => {
        setInitData(values);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching leaderboard data:', error);
        setLoading(false);
      });
  }, []);
  return (
    <section className="moca-table moca-leaderboard" id="moca">
      <div className="moca-title">
        <img src={mocaIcon} alt="" />
        1. Moca Leaderboard
      </div>
      <div className="sort-filter">
        <div className="sort-tabs">
          <button
            className={`btn-sort ${isTotalScore ? '' : 'active'}`}
            onClick={() => setIsTotalScore(false)}
          >
            High Score
          </button>
          <button
            className={`btn-sort  ${!isTotalScore ? '' : 'active'}`}
            onClick={() => setIsTotalScore(true)}
          >
            Total Score
          </button>
        </div>
        <div className="moca-search-filter">
          <div className="moca-search">
            <img src={iconSearch} alt="icon-search" />
            <input
              type="text"
              name="search"
              value={searchInput}
              placeholder="Search by Moca name or Gamee ID"
              onChange={handleInputChange}
              required
            />
            {searchInput.length !== 0 && (
              <button onClick={handleRemoveSearch}>
                <img src={iconRemoveS} alt="icon-r-search" />
              </button>
            )}
          </div>
          <div className="mocana-filter">
            <div className="mocana-button-sort">
              <button onClick={() => setShowSort(!showSort)}>
                <span>Sort</span>
                <span>
                  <img src={iconSort} alt="" />
                  <img src={iconSort} alt="" />
                </span>
              </button>
            </div>
            <div className={`mocana-list-sort ${showSort && 'active'}`}>
              <Sort setShowSort={handleShowSort} setValueSort={setOrdering} />
              <div onClick={() => handleShowSort()} className="toggle-button">
                <img src={iconClose} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="moca-results">
        {data && data.length === 0 ? (
          <NoData text="Moca / Player is not registered in this experience." />
        ) : (
          <Table
            data={data}
            count={count}
            setPage={setPage}
            loading={loading}
            setOffset={setOffset}
            setValueSort={setOrdering}
            currentPage={page}
            isTotalScore={isTotalScore}
          />
        )}
      </div>
    </section>
  );
};
export default MocaLeaderboard;
