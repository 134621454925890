import React, { useState } from 'react';
import EventHeader from './EventHeader';
import EventConnectionWrapper from './EventConnectionWrapper';

import Tabs from './Tabs';
import LeaderBoard from './LeaderBoard';
import Reward from './Reward';

const tabs = [
  {
    key: 'Rewards',
    text: <>Rewards</>,
  },
  {
    key: 'LeaderBoard',
    text: <>Leaderboards</>,
  },
];

const EventWrapper = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);
  const handleSelectedTab = (value) => {
    setSelectedTab(value);
  };

  return (
    <div className="mocana-wrapper">
      <EventHeader />
      <EventConnectionWrapper>
        <Tabs
          items={tabs}
          selectedTab={selectedTab}
          handleSelectedTab={handleSelectedTab}
        />

        <div
          className={`tab-content ${
            selectedTab === tabs[0].key ? 'selected' : ''
          }`}
        >
          <Reward />
        </div>
        <div
          className={`tab-content ${
            selectedTab === tabs[1].key ? 'selected' : ''
          }`}
        >
          <LeaderBoard />
        </div>
      </EventConnectionWrapper>
    </div>
  );
};

export default EventWrapper;
