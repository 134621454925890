import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import ConnectWallet from './ConnectWallet';
import NavLinks from './NavLinks';

const DesktopNavWrapper = ({
  links,
  className,
  showDesktopNav,
  address,
  handleGetUpdate,
  handleDisconnect,
}) => {
  const [showModal, setShowModal] = useState(false);

  const top = showDesktopNav.display ? 0 : -1000;

  const handleClick = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (address) {
      setShowModal(false);
    }
  }, [address]);

  return (
    <>
      {showModal && <Modal toggleModal={() => setShowModal(false)} />}
      <nav
        style={{
          top,
        }}
        className="nav-desktop"
      >
        <ul className={`desktop ${className}`}>
          <NavLinks
            type="desktop"
            links={links}
            handleShowDesktopNav={() => showDesktopNav({ display: true })}
          />
          <li>
            <ConnectWallet
              address={address}
              handleGetUpdate={handleGetUpdate}
              handleDisconnect={handleDisconnect}
              handleClick={handleClick}
            />
          </li>
        </ul>
      </nav>
    </>
  );
};

export default DesktopNavWrapper;
