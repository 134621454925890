import React from 'react';
import { useEventTimeCountDown } from '../../hooks/useCountDown';
import { EVENT_TIME_STATUS } from '../../utils/eventHelpers';
import { TimeRemindIcon } from '../common/Icons';

const EventTitleTest = ({ className, data }) => {
  const { statusEvent } = useEventTimeCountDown();
  // console.log(1, statusEvent);
  const converTime = (isoDate) => {
    const dateObj = new Date(isoDate);
    const options = {
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    return dateObj.toLocaleDateString('en-US', options);
  };
  if (!data) {
    return <></>;
  }
  return (
    <div className={`event-title-wrapper ${className ?? ''}`}>
      <h1 className="event-title">{data.name}</h1>
      <div className="event-title-notify">
        <p className="event-title-date">
          <TimeRemindIcon className="event-title-icon" />
          {converTime(data.start_date)}- {converTime(data.end_date)}(UTC)
          {/* {startedDate}- {endedDate} (UTC) */}
        </p>
        <p className="event-title-status">
          {statusEvent === EVENT_TIME_STATUS.NOT_STARTED && (
            <span>Not Started</span>
          )}
          {statusEvent === EVENT_TIME_STATUS.LIVE && (
            <span className="live">Live</span>
          )}
          {statusEvent === EVENT_TIME_STATUS.ENDED && (
            <span className="ended">Ended</span>
          )}
        </p>
        <p className="event-title-type">
          <span className="partnership">{data.type}</span>
          {/* <span className="odyssey">Odyssey</span> */}
        </p>
      </div>
    </div>
  );
};

export default EventTitleTest;
